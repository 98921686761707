// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';


// custom ...

b{
    font-size:1.3em;
    color: $blue;
}

.login-page{

    margin: 0;
    padding: 0;
    background-color: $primary;
    color: $primary;
    height: 100vh;

      #login .container #login-row #login-column #login-box {
        margin-top: 120px;
        max-width: 600px;
        height: 320px;

        border: 1px solid #9C9C9C;
        background-color: #EAEAEA;
      }
      #login .container #login-row #login-column #login-box #login-form {
        padding: 20px;
      }
      #login .container #login-row #login-column #login-box #login-form #register-link {
        margin-top: -85px;
      }

}

.page-item{

    .page-link.rounded-circle{
        width: 5rem;
        height: 5rem;
        margin-left: 0.5rem;
        overflow: hidden

    }
}

.overflow-auto{
    scroll-behavior: smooth;
}

.bg-alcon-secondary {
    background-color: $alcon-gray1 !important;
}

.title-nav{
    background-color: $alcon-gray2;
}
.bg-alcon-secondary2{
    background-color: #c8d2dc,
}

.mytabs a{

    &>span{
        display: inline-block;
        width: 0.8em;
        height: 0.8em;
        margin-left: .2rem;
        border:1px solid $primary ;
        &.active{
            background-color: $primary;
        }
    }
}

.input-group-sm {
    min-width: 4rem;
}

.final-summary{
    .list-group-item{
        padding: .25rem 1rem;
    }
}

@media all {
}

@media print {
    @page {size: landscape}
    section.page-break { display: block; page-break-before: always; }
    .btn-primary { display:none; }
}



.pdf-render{
    font-family: "DejaVu Sans", "Arial", sans-serif;
    font-size: 12px;
    width: 1300px;

    section{
        width: 1000px;
    }
  }
@font-face {
    font-family: "DejaVu Sans";
    src: url("https://kendo.cdn.telerik.com/2020.3.1021/styles/fonts/DejaVu/DejaVuSans.ttf") format("truetype");
  }

  @font-face {
    font-family: "DejaVu Sans";
    font-weight: bold;
    src: url("https://kendo.cdn.telerik.com/2020.3.1021/styles/fonts/DejaVu/DejaVuSans-Bold.ttf") format("truetype");
  }

  @font-face {
    font-family: "DejaVu Sans";
    font-style: italic;
    src: url("https://kendo.cdn.telerik.com/2020.3.1021/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf") format("truetype");
  }

  @font-face {
    font-family: "DejaVu Sans";
    font-weight: bold;
    font-style: italic;
    src: url("https://kendo.cdn.telerik.com/2020.3.1021/styles/fonts/DejaVu/DejaVuSans-Oblique.ttf") format("truetype");
  }
